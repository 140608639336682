import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import { WordCarousel } from "../components/WordCarousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NewsToday from "../components/NewsToday";
import Articles from "../components/Articles";
import Columnists from "../components/Columnists";
import SecondaryArticles from "../components/SecondaryArticles";
import VideoGallery from "./../components/VideoGallery";
import { ErrorBoundary } from "react-error-boundary";
import BigDataEnterprises from "../components/BigDataEnterprises";
import PublicidadGreenTower from "../../src/assets/img/assets/green-tower.gif";
import DrenajeLinfatico from "../../src/assets/img/assets/drenaje-linfatico.jpg";
import BannerDerecha from "../../src/assets/img/assets/banner-derecha.gif";
import ErrorMessage from "../components/ErrorMessage";
import LastMomentGallery from "../components/LastMomentGallery";
import OrderSection from "./OrderSection";

const HomePage = () => {
  return (
    <>
      <ErrorBoundary fallback={<ErrorMessage />}>
        <Header />
      </ErrorBoundary>
      {/* ========== WRAPPER ========== */}
      <div className="wrapper">
        {/*Content start*/}
        <main id="content">
          <div className="container">
            <div className="row">
              {/* top section */}

              <div className="col-12 mt-05">
                {/*Slider ticker*/}
                <div className="row d-none d-md-inline-block">
                  {/*Breaking box*/}
                  {/* <div className="col-2 col-md-2">
                    <div className="d-md-block bg-primary text-white text-center py-1 px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1rem"
                        height="1rem"
                        fill="currentColor"
                        className="bi bi-lightning-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                      </svg>
                      <span>
                        Lo ultimo
                        <BsArrowRight style={{ marginLeft: "5px" }} />
                      </span>
                    </div>
                  </div> */}

                  {/*Breaking content*/}
                  <div className="col-12 col-md-12 d-md-inline-block">
                    <ErrorBoundary fallback={<ErrorMessage />}>
                      <WordCarousel />
                    </ErrorBoundary>
                  </div>
                </div>
                {/*End slider ticker*/}
                {/*Publicidad principal*/}
                <div className="col-lg-12">
                  <div className="my-1 my-md-3 my-lg-2 d-md-block text-center">
                    <a href="#" target="_blank">
                      {/* <img className="img-fluid" src="./src/assets/img/ads/green-tower.gif" alt="Advertiser space"> */}
                      <img
                        className="img-fluid"
                        src={PublicidadGreenTower}
                        alt="Publicidad"
                      />
                    </a>
                  </div>
                </div>

                {/* Destacadas y NewsToday */}
                <ErrorBoundary fallback={<ErrorMessage />}>
                  <OrderSection />
                </ErrorBoundary>
                {/* Final Noticias Destacadas y NewsToday*/}
              </div>
              {/*start left column*/}
              <ErrorBoundary fallback={<ErrorMessage />}>
                <BigDataEnterprises />
              </ErrorBoundary>

              {/*end left column*/}

              {/*Publicidad derecha*/}
              <aside className="col-md-4">
                <div className="sticky">
                  {/*Publicidad derecha*/}
                  <aside className="widget">
                    <div className="textwidget custom-html-widget">
                      <div className="hover-a text-center">
                        {/*title*/}
                        <div className="py-1">
                          {/*<span className="text-mute small">
                            - Tercera Publicidad -
                          </span>*/}
                        </div>
                        {/*content*/}
                        <a href="#" target="_blank" rel="noopener">
                          <img
                            width="60%"
                            className="img-fluid bg-light"
                            src={BannerDerecha}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </aside>
                </div>
              </aside>
              {/* Fin publicidad derecha*/}
              {/* start full columnistas */}
              <ErrorBoundary fallback={<ErrorMessage />}>
                <Columnists />
              </ErrorBoundary>
              {/* end full columnnistas  */}

              {/* start ultimo momento */}
              <ErrorBoundary fallback={<ErrorMessage />}>
                <LastMomentGallery />
              </ErrorBoundary>
              {/* end ultimo momento */}

              {/* start video galeria */}
              <ErrorBoundary fallback={<ErrorMessage />}>
                <VideoGallery />
              </ErrorBoundary>
              {/* end video galeria */}
            </div>
          </div>
        </main>
        {/*End Content*/}
      </div>
      {/* ========== END WRAPPER ========== */}
      <ErrorBoundary fallback={<ErrorMessage />}>
        <Footer />
      </ErrorBoundary>
      {/*Back to top*/}
    </>
  );
};
export default HomePage;
