import PropTypes from "prop-types";
const PostTags = ({ tags }) => {
  return (
    <div className="entry-footer">
      {/* tags */}
      <div className="tags-area">
        <ul className="tags-links tagcloud ps-0 mb-0 pb-0">
          <li>
            <span className="fw-bold me-2">Etiquetas</span>
          </li>
          {tags?.map((tag) => (
            <li key={tag}>
              <a href="#">{tag}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
PostTags.propTypes = {
  tags: PropTypes.array,
};

export default PostTags;
