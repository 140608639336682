import { Link, NavLink } from "react-router-dom";
// import LogoMobile from "./logo_datapolis_fondo_negro.png";
import LogoMobile from "./fondo-fotor-2023090281823.jpg";
import MainLogo from "./fondo-fotor-2023090281823.jpg";
import { actualDate } from "../../utils";
import { useState } from "react";

const Header = () => {
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
  const handleVisibleMenu = () => {
    setVisibleMobileMenu(!visibleMobileMenu);
  };
  return (
    <>
      {/* {/*Skippy*/}
      <a id="skippy" className="visually-hidden-focusable" href="#content">
        <div className="container">
          <span className="skiplink-text">Skip to main content</span>
        </div>
      </a>
      {/*Background image*/}
      <div className="bg-image"></div>
      {/* ========== WRAPPER ========== */}
      <div className="wrapper">
        {/*Header start*/}
        <header className="header">
          {/*top mobile menu start*/}
          <div className="top-menu bg-white">
            <div className="container">
              {/*Navbar Mobile*/}
              <nav className="navbar navbar-expand d-lg-none navbar-light px-0">
                <div
                  id="navbar-mobile"
                  className="collapse navbar-collapse nav-top-mobile"
                >
                  {/*Top left menu*/}
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="../pages/about-us.html">
                        Acerca de
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="../pages/contact-us.html">
                        Contactanos
                      </a>
                    </li>
                  </ul>
                  {/*Top right menu*/}
                  <ul className="navbar-nav ms-auto text-center">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://facebook.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="facebook"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://twitter.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-twitter"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://youtube.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="youtube"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-youtube"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://instagram.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="instagram"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                          <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                          <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://vimeo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="vimeo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M476.9,114c-5-23.39-17.51-38.78-40.61-46.27s-64.92-4.5-94.12,16.79c-26.79,19.51-46.26,54.42-54,78.28a4,4,0,0,0,5.13,5c10.77-3.8,21.72-7.1,34-6.45,15,.8,24.51,12,24.91,25.29.3,9.79-.2,18.69-3.6,27.68C337.87,243,321,270.78,301.06,295.07a72.49,72.49,0,0,1-10,9.89c-10.21,8.29-18.81,6.1-25.41-5.2-5.4-9.29-9-18.88-12.2-29.08-12.4-39.67-16.81-80.84-23.81-121.52-3.3-19.48-7-39.77-18-56.86-11.6-17.79-28.61-24.58-50-22-14.7,1.8-36.91,17.49-47.81,26.39,0,0-56,46.87-81.82,71.35l21.2,27s17.91-12.49,27.51-18.29c5.7-3.39,12.4-4.09,17.2.2,4.51,3.9,9.61,9,12.31,14.1,5.7,10.69,11.2,21.88,14.7,33.37,13.2,44.27,25.51,88.64,37.81,133.22,6.3,22.78,13.9,44.17,28,63.55,19.31,26.59,39.61,32.68,70.92,21.49,25.41-9.09,46.61-26.18,66-43.87,33.11-30.18,59.12-65.36,85.52-101.14C433.59,270,450.49,242,464.59,210.72,478.5,179.74,484,147.26,476.9,114Z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                  {/*end top right menu*/}
                </div>
              </nav>
              {/* End Navbar Mobile*/}
            </div>
          </div>
          {/* end top mobile menu */}

          {/* top menu */}
          <div className="mobile-sticky is-sticky fs-6 bg-white">
            <div className="container">
              {/*Navbar*/}
              <nav className="navbar navbar-expand-lg navbar-dark px-0 py-0">
                {/*Hamburger button*/}
                <a
                  id="showStartPush"
                  onClick={handleVisibleMenu}
                  aria-label="sidebar menu"
                  className="navbar-toggler sidebar-menu-trigger side-hamburger border-0 px-0 mx-2"
                  href="#"
                >
                  <span className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </a>
                {/* Mobile logo */}
                <Link to="/">
                  <img
                    className="mobile-logo img-fluid d-lg-none mx-auto"
                    src={LogoMobile}
                    alt="Logo site"
                  />
                </Link>
                {/*Right Toggle Button*/}
                <button
                  className="navbar-toggler px-0 invisible"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo2"
                  aria-controls="navbarTogglerDemo2"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    height="1.5rem"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z"
                      // style={
                      //   "fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:32px"
                      // }
                    />
                    {/* <line
                      x1="338.29"
                      y1="338.29"
                      x2="448"
                      y2="448"
                      style={
                      "fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"
                      }
                    /> */}
                  </svg>
                </button>

                {/*Top Navbar*/}
                <div
                  id="navbarTogglerDemo"
                  className="collapse navbar-collapse"
                >
                  {/* Time */}
                  <div className="date-area d-none d-lg-block">
                    <time
                      className="text-primary fw-bold navbar-text me-2"
                      dateTime="2023-10-28"
                    >
                      {actualDate()}
                    </time>
                  </div>
                  {/*Top left menu*/}
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        className="text-danger fw-bold link nav-link active"
                        to={"/"}
                      >
                        Principal
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      {/* <Link
                        className="text-primary fw-bold link nav-link"
                        to={"/acerca"}
                      >
                        Acerca de
                      </Link> */}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="text-primary fw-bold link nav-link"
                        to={"/contactanos"}
                      >
                        Contactanos
                      </Link>
                    </li>
                  </ul>
                  {/*end top left menu*/}
                  {/*Top right menu*/}
                  <ul className="navbar-nav ms-auto text-center">
                    <li className="nav-item">
                      <a
                        className="text-danger nav-link"
                        href="https://www.facebook.com/datapolisbo"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="facebook"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="text-primary nav-link"
                        href="https://twitter.com/datapolisbo"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                      >
                        <svg
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-twitter"
                          viewBox="0 0 1200 1227"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="text-danger nav-link"
                        href="https://www.youtube.com/@DataPolisBo"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="youtube"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="bi bi-youtube"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="text-primary nav-link"
                        href="https://www.instagram.com/datapolis_bolivia"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="instagram"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                          <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                          <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                        </svg>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://vimeo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="vimeo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M476.9,114c-5-23.39-17.51-38.78-40.61-46.27s-64.92-4.5-94.12,16.79c-26.79,19.51-46.26,54.42-54,78.28a4,4,0,0,0,5.13,5c10.77-3.8,21.72-7.1,34-6.45,15,.8,24.51,12,24.91,25.29.3,9.79-.2,18.69-3.6,27.68C337.87,243,321,270.78,301.06,295.07a72.49,72.49,0,0,1-10,9.89c-10.21,8.29-18.81,6.1-25.41-5.2-5.4-9.29-9-18.88-12.2-29.08-12.4-39.67-16.81-80.84-23.81-121.52-3.3-19.48-7-39.77-18-56.86-11.6-17.79-28.61-24.58-50-22-14.7,1.8-36.91,17.49-47.81,26.39,0,0-56,46.87-81.82,71.35l21.2,27s17.91-12.49,27.51-18.29c5.7-3.39,12.4-4.09,17.2.2,4.51,3.9,9.61,9,12.31,14.1,5.7,10.69,11.2,21.88,14.7,33.37,13.2,44.27,25.51,88.64,37.81,133.22,6.3,22.78,13.9,44.17,28,63.55,19.31,26.59,39.61,32.68,70.92,21.49,25.41-9.09,46.61-26.18,66-43.87,33.11-30.18,59.12-65.36,85.52-101.14C433.59,270,450.49,242,464.59,210.72,478.5,179.74,484,147.26,476.9,114Z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                  {/*end top right menu*/}
                </div>
              </nav>

              {/* End Navbar */}
              {/*search mobile*/}
              <div
                className="collapse navbar-collapse col-12 py-2"
                id="navbarTogglerDemo2"
              >
                <form className="form-inline" action="../category/search.html">
                  <div className="input-group w-100">
                    <input
                      type="text"
                      className="form-control border border-end-0"
                      placeholder="Search..."
                      aria-label="search"
                    />
                    <button className="btn btn-primary" type="submit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1rem"
                        height="1rem"
                        fill="currentColor"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z"
                          // style={
                          //   "fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:32px"
                          // }
                        />
                        {/* <line
                          x1="338.29"
                          y1="338.29"
                          x2="448"
                          y2="448"
                          style={
                            "fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"
                          }
                        /> */}
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              {/*end search mobile*/}
            </div>
          </div>
          {/*end top menu*/}

          {/*start second header*/}
          <div className="second-header">
            <div className="container">
              <div className="row">
                {/*Left content logo*/}
                <div className="col-lg-12 text-center">
                  <div id="main-logo" className="my-2 d-none d-lg-block">
                    <Link className="navbar-brand" to={"/"}>
                      <img
                        className="img-fluid"
                        src={MainLogo}
                        alt="Logo site"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*End second header*/}
        </header>
        {/*End header*/}

        {/*Main menu*/}
        <div
          id="showbacktop"
          className="showbacktop full-nav bg-white border-none border-lg-1 border-bottom shadow-b-sm py-0"
        >
          <div className="container">
            <nav
              id="main-menu"
              className="main-menu navbar navbar-expand-lg navbar-light px-2 px-lg-0 py-0"
            >
              {/*Navbar menu*/}
              <div
                id="navbarTogglerDemo1"
                className="collapse navbar-collapse hover-mode"
              >
                {/* logo in navbar */}
                <div className="logo-showbacktop">
                  <a href="index.html">
                    <img
                      className="img-fluid"
                      src="./src/assets/img/assets/default-logo.png"
                      alt="Logo site"
                    />
                  </a>
                </div>
                {/*end search form*/}
              </div>
              {/*End navbar menu*/}
            </nav>
          </div>
        </div>
        {/* End main menu */}

        {/* sidebar mobile menu */}
        <div className="mobile-side">
          <nav
            id="mobile-menu"
            className={`${
              visibleMobileMenu ? "" : "menu-mobile"
            } d-flex flex-column push push-start shadow-r-sm bg-white`}
          >
            {/* mobile menu content */}
            <div className="mobile-content mb-auto">
              {/*navigation*/}
              <div className="sidenav-menu">
                <nav className="navbar navbar-light navbar-inverse">
                  <ul
                    id="side-menu"
                    className="nav navbar-nav list-group list-unstyled side-link"
                  >
                    <li className="menu-item nav-item">
                      <Link to="/" className="nav-link active">
                        Principal
                      </Link>
                    </li>
                    {/* dropdown menu start */}
                    {/* <li className="menu-item menu-item-has-children dropdown mega-dropdown nav-item">
                      <a
                        href="#"
                        className="dropdown-toggle nav-link"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="menu-11"
                      >
                        Automotive
                      </a> */}
                    {/* dropdown content */}
                    {/* <ul
                        className="dropdown-menu"
                        aria-labelledby="menu-11"
                        role="menu"
                      >
                        <li className="menu-item nav-item">
                          <a
                            href="../category/category.html"
                            className="dropdown-item"
                          >
                            Cars
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    {/* end dropdown menu */}

                    <li className="menu-item nav-item">
                      <Link to={"/acerca"} className="nav-link">
                        Acerca de
                      </Link>
                    </li>
                    <li className="menu-item nav-item">
                      <Link to={"/contactanos"} className="nav-link">
                        Contactanos
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </nav>
        </div>
        {/* end sidebar mobile menu */}
      </div>
    </>
  );
};
export default Header;
