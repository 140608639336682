import useFetchData from "../assets/js/services/useFetchData";
import PropTypes from "prop-types";

const AuthorDetails = ({ author }) => {
  const url = `autors/${author?.data?.id}?populate=*`;
  // console.log(url);
  const { result: authorDetail, error, loading } = useFetchData(url);
  // console.log(authorDetail);
  // console.log(error);
  // console.log(loading);
  return (
    <div className="media author-box py-1">
      <div className="media-figure">
        <a href="../category/author.html">
          <img
            className="avatar-lg avatar-rounded border img-fluid"
            src={authorDetail?.attributes?.foto?.data?.attributes?.url}
            alt="author"
            loading="lazy"
          />
        </a>
      </div>
      <div className="ms-3 media-body">
        {/*name*/}
        <h4 className="h5">
          <span className="fw-bold">{authorDetail?.attributes?.nombre}</span>
        </h4>
        {/*description*/}
        <p>{authorDetail?.attributes?.profesion}</p>
        {/*social*/}
        <div className="social mb-4">
          {/*facebook*/}
          {authorDetail?.attributes?.enlaceFacebook && (
            <span className="my-2 me-3">
              <a
                target="_blank"
                href={authorDetail?.attributes?.enlaceFacebook}
                aria-label="Facebook"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  height="1.5rem"
                  fill="currentColor"
                  className="bi bi-facebook text-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
            </span>
          )}

          {/*twitter*/}
          {authorDetail?.attributes?.enlaceTwitter && (
            <span className="my-2 me-3">
              <a
                target="_blank"
                href={authorDetail?.attributes?.enlaceTwitter}
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                <svg
                  width="1.5rem"
                  height="1.5rem"
                  fill="currentColor"
                  className="bi bi-twitter text-twitter"
                  viewBox="0 0 1200 1227"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
                </svg>
              </a>
            </span>
          )}
          {/*tiktok*/}
          {authorDetail?.attributes?.enlaceTikTok && (
            <span className="my-2 me-3">
              <a
                target="_blank"
                href={authorDetail?.attributes?.enlaceTikTok}
                aria-label="Tiktok"
                rel="noopener noreferrer"
              >
                <svg
                  width="1.5rem"
                  height="1.5rem"
                  fill="#000000"
                  viewBox="0 0 512 512"
                  id="icons"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"></path>
                  </g>
                </svg>
              </a>
            </span>
          )}
          {/*youtube*/}
          {authorDetail?.attributes?.enlaceYoutube && (
            <span className="my-2 me-3">
              <a
                target="_blank"
                href={authorDetail?.attributes?.enlaceYoutube}
                aria-label="Youtube"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  height="1.5rem"
                  fill="currentColor"
                  className="bi bi-youtube text-youtube"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                </svg>
              </a>
            </span>
          )}
          {/*instagram*/}
          {authorDetail?.attributes?.enlaceInstagram && (
            <span className="my-2 me-3">
              <a
                target="_blank"
                href={authorDetail?.attributes?.enlaceInstagram}
                aria-label="Instagram"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-instagram"
                  width="1.5rem"
                  height="1.5rem"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                >
                  <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                  <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                  <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                </svg>
              </a>
            </span>
          )}
          {/*end instagram*/}
        </div>

        {/*end social*/}
      </div>
    </div>
  );
};
AuthorDetails.propTypes = {
  author: PropTypes.object,
};
export default AuthorDetails;
