import { Link } from "react-router-dom";
import useFetchData from "../assets/js/services/useFetchData";
import { uniqueOrdenValues } from "../utils";

const Columnists = () => {
  const { error, result, loading } = useFetchData(
    `columnistas?sort[0]=orden:asc&sort[1]=id:desc&&populate[autor][populate]=*`
  );
  // console.log(result);
  // Filter unique fields from results taking acount orden field
  const uniqueColumnists = uniqueOrdenValues(result, "orden");
  // console.log(uniqueColumnists);

  const firstFiveColumnists = uniqueColumnists?.slice(0, 5);
  // console.log(firstFiveColumnists);
  return (
    <div className="col-12 mb-4">
      {/* Block start */}
      <div className="block-area p-4 border bg-light-black">
        {/* Block title */}
        <div className="block-title-13">
          <h4 className="h5 title-box-dot">
            <span>Opinión - Análisis</span>
          </h4>
          <div className="dot-line"></div>
        </div>
        {/* block content */}
        {/* Start Columnistas */}
        <div className="block-area">
          <div className="row justify-content-center">
            {/* Primer columnista*/}
            {firstFiveColumnists?.map((columnist) => (
              <div key={columnist.id} className="col-md-2">
                <div className="about-content text-center">
                  <div className="about-img">
                    <img
                      className="img-fluid avatar-lg rounded-circle mb-2"
                      alt="profile images"
                      src={
                        columnist?.attributes?.autor.data.attributes.foto.data
                          .attributes.url
                      }
                      loading="lazy"
                    />
                    <h6>
                      {columnist?.attributes?.autor.data.attributes.profesion}
                    </h6>
                    <h5 className="fw-bold">
                      {columnist?.attributes?.autor.data.attributes.nombre}
                    </h5>
                    <h4 className="text-muted">
                      {columnist?.attributes?.titulo}
                    </h4>
                    {columnist?.attributes?.articulo && (
                      <p className="text-primary link">
                        <Link to={`/opinion/${columnist?.attributes?.slug}`}>
                          Artículo
                        </Link>
                      </p>
                    )}
                    {columnist?.attributes?.enlaceVideo && (
                      <a
                        href={columnist?.attributes?.enlaceVideo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-danger link"
                      >
                        Video
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Columnistas */}
        {/*end block content*/}
      </div>
      {/* End block */}
    </div>
  );
};
export default Columnists;
