import { Link } from "react-router-dom";
import { categoriesColors, dateFormat } from "../utils";

const MainArticles = ({ firstTwoArticles }) => {
  return firstTwoArticles?.map((article) => (
    <article key={article.id} className="card card-full hover-a py-4">
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-6">
          <div className="card-full text-white overflow zoom">
            <div className="height-ratio image-wrapper">
              {/*thumbnail*/}
              <Link to={`/post/noticias/${article?.attributes.slug}`}>
                <img
                  className="image-full-container__principalNews"
                  src={article?.attributes.imagen.data.attributes.url}
                  alt="Image description"
                  loading="lazy"
                />
              </Link>
              {/* category destacada */}
              <div className="position-absolute px-2 pt-0 b-0 w-100 bg-shadow">
                <a
                  className={`h6 badge ${categoriesColors(
                    article?.attributes.categoria.data.attributes.descripcion
                  )} text-white`}
                >
                  {article?.attributes.categoria.data.attributes.descripcion}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-6">
          <div className="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            {/*title*/}
            <h3 className="card-title h2 h3-sm h2-md">
              <Link to={`/post/noticias/${article?.attributes.slug}`}>
                {article?.attributes.titular}
              </Link>
            </h3>
            {/*author and date*/}
            <div className="card-text mb-2 text-muted small">
              <span className="d-none d-sm-inline me-1">
                <a className="fw-bold" href="#">
                  {article?.attributes.autor.data.attributes.nombre}
                </a>
              </span>
              <time dateTime="2023-10-21">
                {dateFormat(article?.attributes.createdAt)}
              </time>
            </div>
            {/*description*/}
            <p className="card-text d-none d-sm-block">
              {article?.attributes.pretitular}
            </p>
          </div>
        </div>
      </div>
    </article>
  ));
};
export default MainArticles;
