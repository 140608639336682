import useFetchData from "../assets/js/services/useFetchData";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { BsDot } from "react-icons/bs";

export const WordCarousel = () => {
  const { result, error, loading } = useFetchData(
    "noticias?sort[0]=createdAt:desc&sort[1]=orden&populate=*"
  );
  // console.log(error);
  // console.log(loading);
  // console.log(result);
  const firstThreeArticles = result?.slice(0, 3);
  // console.log(firstThreeArticles);
  return (
    <>
      <Marquee pauseOnHover={true}>
        {firstThreeArticles?.map((news_data) => {
          return (
            <NavLink
              className="fw-medium lead-md link"
              key={news_data.id}
              to={`/post/noticias/${news_data?.attributes.slug}`}
            >
              <BsDot
                className="my-1 mx-0 px-0"
                size={30}
                color="var(--bs-primary)"
              />
              {`${news_data?.attributes.titular}`}
            </NavLink>
          );
        })}
      </Marquee>
    </>
  );
};
