import { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../../../utils";

const useFetchData = (url) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        //console.log("USEE");
        setError(false);
        setLoading(true);
        const res = await fetch(API_URL + url);
        const json = await res.json();
        if (!json.data) {
          throw Error("No se pueden obtener las noticias!");
        }
        setResult(json.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        console.log(error);
      }
    })();
  }, [url]);
  return { result, loading, error };
};
export default useFetchData;
