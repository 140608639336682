import { Link } from "react-router-dom";
import LogoMobile from "./Header/datapolis-logo-blanco.png";

const Footer = () => {
  return (
    <>
      {/*Footer start*/}
      <footer>
        {/*Footer content*/}
        <div id="footer" className="footer-dark bg-dark bg-footer py-5 px-3">
          <div className="container">
            <div className="row">
              {/* left widget */}
              <div className="widget col-md-6">
                {/* <h3 className="h5 widget-title border-bottom border-smooth">Acerca de</h3> */}
                <div className="widget-content">
                  <img
                    className="footer-logo img-fluid mb-2"
                    src={LogoMobile}
                    alt="footer logo"
                    loading="lazy"
                  />
                  <p>
                    Datápolis.bo es un medio de comunicación digital que informa
                    sobre la actualidad nacional e internacional de manera
                    independiente.
                  </p>
                  <p>
                    Publica opiniones y análisis sobre la base de la pluralidad
                    de pensamiento.
                  </p>
                  <p className="footer-info mb-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1rem"
                      height="1rem"
                      fill="currentColor"
                      className="bi bi-envelope-fill me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                    </svg>
                    contacto@datapolis.bo
                  </p>
                  <div className="social mb-4">
                    {/*facebook*/}
                    <span className="my-2 me-3">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/datapolisbo"
                        aria-label="Facebook"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5rem"
                          height="1.5rem"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </a>
                    </span>
                    {/*twitter*/}
                    <span className="my-2 me-3">
                      <a
                        target="_blank"
                        href="https://twitter.com/datapolisbo"
                        aria-label="Twitter"
                        className="bi bi-twitter"
                        rel="noopener noreferrer"
                      >
                        <svg
                          width="1.5rem"
                          height="1.5rem"
                          fill="currentColor"
                          viewBox="0 0 1200 1227"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </span>
                    {/*youtube*/}
                    <span className="my-2 me-3">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@DataPolisBo"
                        aria-label="Youtube"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5rem"
                          height="1.5rem"
                          fill="currentColor"
                          className="bi bi-youtube"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                        </svg>
                      </a>
                    </span>
                    {/*instagram*/}
                    <span className="my-2 me-3">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/datapolis_bolivia"
                        aria-label="Instagram"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5rem"
                          height="1.5rem"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                          <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                          <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                        </svg>
                      </a>
                    </span>
                    {/*end instagram*/}
                  </div>
                </div>
              </div>
              {/* Weather Widget*/}
              <div className="widget col-md-6">
                <a
                  className="weatherwidget-io"
                  href="https://forecast7.com/es/n16d49n68d12/la-paz/"
                  data-label_1="LA PAZ"
                  data-label_2="Tiempo"
                  data-theme="dark"
                >
                  LA PAZ Tiempo
                </a>
                {
                  !(function (d, s, id) {
                    var js,
                      fjs = d.getElementsByTagName(s)[0];
                    if (!d.getElementById(id)) {
                      js = d.createElement(s);
                      js.id = id;
                      js.src = "https://weatherwidget.io/js/widget.min.js";
                      fjs.parentNode.insertBefore(js, fjs);
                    }
                  })(document, "script", "weatherwidget-io-js")
                }
              </div>
            </div>
          </div>
        </div>
        {/*End footer content*/}
        {/* footer copyright menu */}
        <div className="footer-copyright bg-secondary">
          <div className="container">
            {/*Navbar*/}
            <nav className="navbar navbar-expand navbar-dark px-0">
              {/*footer left menu*/}
              <ul className="navbar-nav footer-nav text-center first-start-lg-0">
                <li className="nav-item">
                  {/* <Link className="nav-link" to={"/acerca"}>
                    Acerca de
                  </Link> */}
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/contactanos"}>
                    Contactanos
                  </Link>
                </li>
              </ul>
              {/*end footer left menu*/}
              {/* footer copyright */}
              <ul className="navbar-nav ms-auto text-center">
                <li className="d-inline navbar-text">
                  &copy; Copyright datapolis
                </li>
              </ul>
              {/* end footer copyright */}
            </nav>
            {/* End Navbar */}
          </div>
        </div>
        {/*end copyright menu*/}
      </footer>
      {/* End Footer */}
    </>
  );
};
export default Footer;
