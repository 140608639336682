import PropTypes from "prop-types";

const SocialComponent = ({ urlPost }) => {
  return (
    <div className="social-share mb-3">
      {/* share facebook */}
      <a
        className="btn btn-social btn-facebook text-white btn-sm blank-windows"
        href={`https://www.facebook.com/sharer.php?u=${urlPost}`}
        title="Share to facebook"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1rem"
          height="1rem"
          fill="currentColor"
          className="bi bi-facebook me-1"
          viewBox="0 0 16 16"
        >
          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </svg>
        <span className="d-none d-sm-inline">Facebook</span>
      </a>
      {/* share twitter */}
      <a
        className="btn btn-social btn-twitter text-white btn-sm blank-windows"
        href={`https://www.twitter.com/share?url=${urlPost}`}
        title="Share to twitter"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          width="1rem"
          height="1rem"
          fill="currentColor"
          className="bi bi-twitter me-1"
          viewBox="0 0 1200 1227"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
        </svg>
        <span className="d-none d-sm-inline text-white">Twitter</span>
      </a>
      {/* share to whatsapp */}
      <a
        className="btn btn-success text-white btn-sm"
        href={`https://wa.me/?text=${urlPost}`}
        title="Share to whatsapp"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1rem"
          height="1rem"
          className="me-1"
          fill="currentColor"
          viewBox="0 0 512 512"
        >
          <path
            d="M414.73,97.1A222.14,222.14,0,0,0,256.94,32C134,32,33.92,131.58,33.87,254A220.61,220.61,0,0,0,63.65,365L32,480l118.25-30.87a223.63,223.63,0,0,0,106.6,27h.09c122.93,0,223-99.59,223.06-222A220.18,220.18,0,0,0,414.73,97.1ZM256.94,438.66h-.08a185.75,185.75,0,0,1-94.36-25.72l-6.77-4L85.56,427.26l18.73-68.09-4.41-7A183.46,183.46,0,0,1,71.53,254c0-101.73,83.21-184.5,185.48-184.5A185,185,0,0,1,442.34,254.14C442.3,355.88,359.13,438.66,256.94,438.66ZM358.63,300.47c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54,2.78-14.4,18-17.65,21.75-6.5,4.16-12.07,1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56,2.44-11.32c2.51-2.49,5.57-6.48,8.36-9.72s3.72-5.56,5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53,20.53,0,0,0-14.86,6.94c-5.11,5.56-19.51,19-19.51,46.28s20,53.68,22.76,57.38,39.3,59.73,95.21,83.76a323.11,323.11,0,0,0,31.78,11.68c13.35,4.22,25.5,3.63,35.1,2.2,10.71-1.59,33-13.42,37.63-26.38s4.64-24.06,3.25-26.37S364.21,303.24,358.63,300.47Z"
            // style="fill-rule:evenodd"
          />
        </svg>
        <span className="d-none d-sm-inline">Whatsapp</span>
      </a>
      {/* share linkedin */}
      <a
        className="btn btn-social btn-linkedin text-white btn-sm blank-windows"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${urlPost}`}
        title="Share to Linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1rem"
          height="1rem"
          className="me-1"
          fill="currentColor"
          viewBox="0 0 512 512"
        >
          <path d="M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z" />
        </svg>
        <span className="d-none d-sm-inline">Linkedin</span>
      </a>
      {/* share via email */}
      {/* <a
                        className="btn btn-social btn-envelope text-white btn-sm"
                        href="mailto:?subject=Your&nbsp;post&nbsp;title&amp;body=Read&nbsp;complete&nbsp;article&nbsp;in&nbsp;here&nbsp;https://www.bootstrap.news"
                        title="Share by Email"
                      > */}
      {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1rem"
                          height="1rem"
                          className="me-1"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <rect
                            x="48"
                            y="96"
                            width="416"
                            height="320"
                            rx="40"
                            ry="40"
                            // style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"
                          /> */}
      {/* <polyline
                            points="112 160 256 272 400 160"
                            // style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"
                          /> */}
      {/* </svg>
                        <span className="d-none d-sm-inline">Email</span>
                      </a> */}
    </div>
  );
};
SocialComponent.propTypes = {
  urlPost: PropTypes.string,
};
export default SocialComponent;
