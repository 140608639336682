import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import TermsContactPage from "./pages/TermsContactPage";
import PostPage from "./pages/PostPage";
import PostColumnistPage from "./pages/PostColumnistPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/acerca" element={<AboutPage />} />
      <Route path="/contactanos" element={<ContactPage />} />
      <Route path="/terminos" element={<TermsContactPage />} />
      <Route path="/post/:type/:slug/" element={<PostPage />} />
      <Route path="/opinion/:slug/" element={<PostColumnistPage />} />
    </Routes>
  );
}

export default App;
