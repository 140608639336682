import { Carousel } from "react-responsive-carousel";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import useFetchData from "../assets/js/services/useFetchData";
import { dateFormat } from "../utils";

const RelatedAuthorPostsCarousel = ({ idPrincipalNews, idAuthor }) => {
  const url = `columnistas?filters[autor][id][$eq]=${idAuthor}&sort[0]=orden:asc&populate[autor][populate]=*`;
  // console.log(url);
  let { result, error, loading } = useFetchData(url);
  // console.log(result);
  return (
    <>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="col-12 row"
      >
        {result?.map((item) => (
          <>
            <SwiperSlide
              key={item?.id}
              className={`link col-3 col-sm-3 col-md-3 px-1 swiper-slide--related-author`}
            >
              <article>
                {/*post start*/}
                <div className="col-12 mb-3 mb-lg-4 text-center">
                  {/*thumbnail*/}
                  <div className="media-figure">
                    <a href="../category/author.html">
                      <img
                        className="avatar-xl avatar-rounded border img-fluid"
                        src={
                          item?.attributes?.autor?.data.attributes.foto.data
                            .attributes.url
                        }
                        alt="author"
                        loading="lazy"
                      />
                    </a>
                  </div>
                  {/* title & date */}
                  <div>
                    <a href="single1.html">
                      <h5 className="card-title h4 h4-sm h5-md text-dark my-1 link">
                        {item?.attributes?.titulo}
                      </h5>
                    </a>
                    <div className="card-text my-2 dark small text-dark">
                      <time dateTime="2019-10-18">
                        {dateFormat(item?.attributes?.createdAt)}
                      </time>
                    </div>
                  </div>
                </div>
                {/*end post */}
              </article>
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </>
  );
};
export default RelatedAuthorPostsCarousel;
