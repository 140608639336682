import useFetchData from "../assets/js/services/useFetchData";
import { uniqueOrdenValues } from "../utils";
import MainArticles from "./MainArticles";

const Articles = () => {
  const { result, error, loading } = useFetchData(
    "noticias?sort[0]=orden:asc&sort[1]=id:desc&populate=*"
  );
  // console.log(error);
  // console.log(loading);
  // console.log(result);
  const uniqueArticles = uniqueOrdenValues(result, "orden");
  const firstTwoArticles = uniqueArticles?.slice(0, 2);
  // console.log(firstTwoArticles);
  return <MainArticles firstTwoArticles={firstTwoArticles} />;
};
export default Articles;
