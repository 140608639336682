import CounterMain from "./CounterMain";
import DontForget from "./DontForget";
import MainCarousel from "./MainCarousel";
import PropTypes from "prop-types";

const NewsToday = ({ newsData }) => {
  const dataImages = newsData?.imagenes;
  return (
    <div className="row featured-1 mb-5">
      {/*Start left cover*/}
      <div className="block-title-6">
        <h4 className="h5 border-primary">
          <span className="bg-primary text-white">Que hara noticia hoy</span>
        </h4>
      </div>
      <div className="col-md-8 pb-05 pt-05 pe-md-05">
        <div className="position-relative overflow-hidden">
          <div>
            {/*Item slider*/}
            <article className="col-12">
              <MainCarousel
                dataImages={dataImages}
                titleLink={newsData?.titular}
                slug={newsData?.slug}
              />
            </article>
          </div>
        </div>
      </div>

      {/*End left cover*/}

      {/*Start box news*/}
      <div className="col-md-4">
        <div className="row newsbox">
          {/*news box*/}
          <CounterMain
            count={newsData?.nroCifraDia}
            descCount={newsData?.descripcionCifraDia}
          />
          <DontForget frase={newsData?.descripcionNoOlvides} />
          {/*end news box*/}
        </div>
      </div>
      {/*End box news*/}
    </div>
  );
};
NewsToday.propTypes = {
  newsData: PropTypes.object,
};
export default NewsToday;
