import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const MainCarousel = ({ dataImages, titleLink, slug }) => {
  const titles = titleLink?.split(";");
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper text-center"
    >
      {dataImages?.data.map((imgData, index) => (
        <SwiperSlide key={imgData.id}>
          <NavLink
            to={`/post/newsToday/${slug}/`}
            target="_blank"
            className="legend  text-dark text-center rounded link"
            style={{
              display: "block",
              fontSize: "30px",
            }}
            rel="noreferrer"
          >
            {titles[index]}
            <div>
              <img
                style={{ width: "100%", objectFit: "fill" }}
                height="300px"
                alt=""
                src={imgData.attributes.url}
                loading="lazy"
              />
            </div>
          </NavLink>
        </SwiperSlide>
      ))}
    </Swiper>
    // return (
    //   <Carousel
    //     autoPlay
    //     infiniteLoop
    //     showThumbs={false}
    //     transitionTime={4000}
    //     centerSlidePercentage={80}
    //   >
    //     {dataImages?.data.map((imgData, index) => (
    //       <div key={imgData.id}>
    //         <img
    //           width="250px"
    //           height="400px"
    //           alt=""
    //           src={imgData.attributes.url}
    //         />
    //         <Link
    //           to={`/post/newsToday/${slug}/`}
    //           target="_blank"
    //           className="legend card-text text-center text-bg-primary rounded link"
    //           style={{
    //             opacity: "0.7",
    //             fontSize: "20px",
    //           }}
    //           rel="noreferrer"
    //         >
    //           {titles[index]}
    //         </Link>
    //       </div>
    //     ))}
    //   </Carousel>
  );
};
export default MainCarousel;
