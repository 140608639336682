import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const ContactPage = () => {
  return (
    <>
      <Header />
      {/* ========== WRAPPER ========== */}
      <div className="wrapper">
        <main id="content">
          <div className="container">
            <div className="row">
              {/*breadcrumb*/}
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb u-breadcrumb pt-3 px-0 mb-0 bg-transparent small d-none">
                    <li className="breadcrumb-item">
                      <Link to={"/"} className="nav-link active">
                        Principal
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active d-none d-md-block"
                      aria-current="page"
                    >
                      Contactanos
                    </li>
                  </ol>
                </nav>
              </div>
              {/*end breadcrumb*/}
              {/*content*/}
              <div className="col-md-8">
                <div className="post-title">
                  <h1 className="h1 display-4-md display-3-lg mt-2">
                    Contactanos
                  </h1>
                </div>
                <div className="post-content mb-5">
                  <p>
                    Nos encantaría conocer tu opinión. Creemos que tu
                    perspectiva es valiosa y nos gustaría invitarte a compartir
                    tus pensamientos. No importa si es breve o detallado, lo
                    importante es escuchar lo que piensas. ¡Esperamos con
                    interés leer tus comentarios!
                  </p>
                  <div className="pb-2 mt-3 mt-md-4">
                    {/*form start*/}
                    <form className="needs-validation" action="contact.html">
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <input
                              type="text"
                              className="form-control"
                              aria-label="first name"
                              placeholder="Nombre"
                              required
                            />
                            <div className="invalid-feedback">
                              Por favor introduzca su nombre.
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="last name"
                            placeholder="Apellido"
                          />
                          <div className="invalid-feedback">
                            Por favor introduzca su apellido.
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <input
                          name="email"
                          className="form-control"
                          aria-label="email address"
                          placeholder="Correo electronico"
                          type="email"
                          required
                        />
                        <div className="invalid-feedback">
                          Por favor introduzca su correo electronico.
                        </div>
                      </div>
                      <div className="mb-4">
                        <textarea
                          className="form-control"
                          aria-label="insert messages"
                          placeholder="Comentarios"
                          rows="4"
                          required
                        ></textarea>
                        <div className="invalid-feedback">
                          Por favor introduzca sus comentarios
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="custom-control mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="remember"
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember"
                          >
                            Yo acepto los terminos para contactarme
                            <Link to={"/terminos"} target="_blank">
                              Terminos
                            </Link>
                          </label>
                          <div className="invalid-feedback">
                            Por favor acepte los terminos.
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Enviar mensaje
                        </button>
                      </div>
                    </form>
                    {/*end form*/}
                  </div>
                </div>
              </div>
              {/*end content*/}

              {/*right sidebar*/}
              <aside className="col-md-4">
                <aside className="widget">
                  {/*block title*/}
                  <div className="block-title-6 pt-0 mt-3 mt-md-0">
                    <h4 className="h5 border-primary border-2">
                      <span className="bg-primary text-white">
                        Información de contacto
                      </span>
                    </h4>
                  </div>
                  {/*address*/}
                  <div className="card">
                    <div className="bg-themes">
                      <b>Contactanos</b>
                      <p>Email: contacto@datapolis.bo</p>
                    </div>
                  </div>
                  {/*end address*/}
                  <div className="gap-15"></div>
                </aside>
              </aside>
              {/*end right sidebar*/}
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};
export default ContactPage;
