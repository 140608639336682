import { ErrorBoundary } from "react-error-boundary";
import SecondaryArticles from "../components/SecondaryArticles";
import ErrorMessage from "../components/ErrorMessage";
import Articles from "../components/Articles";
import NewsToday from "../components/NewsToday";
// import DrenajeLinfatico from "../../src/assets/img/assets/drenaje-linfatico.jpg";
import DrenajeLinfatico from "../../src/assets/img/assets/fisionova_drenaje.gif";
import useFetchData from "../assets/js/services/useFetchData";

const OrderSection = () => {
  // console.log("OrderSection!!");
  const { result, error, loading } = useFetchData(
    "noticia-hoys?sort[1]=id:desc&populate=*"
  );
  //console.log(result);

  let newsData = {};
  if (result !== null) {
    newsData = result[0]?.attributes;
  }
  const noticiasHoyArribaPagina = newsData.arribaPagina;
  if (noticiasHoyArribaPagina) {
    return (
      <div>
        {/* Final Noticias */}
        {/* Big grid slider 1 */}
        <ErrorBoundary fallback={<ErrorMessage />}>
          <NewsToday newsData={newsData} />
        </ErrorBoundary>
        <div className="row">
          {/* start left column */}
          <div className="col-md-6">
            {/* block start */}
            <div className="block-area">
              {/* block title */}
              <div className="block-title-6 d-none d-sm-block">
                <h4 className="h5 border-danger">
                  <span className="bg-danger text-white">Destacadas</span>
                </h4>
              </div>
              {/* block content */}
              <div className="border-bottom-last-0 first-pt-0">
                {/*post start*/}
                <ErrorBoundary fallback={<ErrorMessage />}>
                  <Articles />
                </ErrorBoundary>
              </div>
              {/* end block content */}
            </div>
            {/*end block*/}
          </div>
          {/* end left column */}

          {/* 5 Noticias */}
          <ErrorBoundary fallback={<ErrorMessage />}>
            <SecondaryArticles />
          </ErrorBoundary>

          {/* start right column ANUNCIO */}
          <aside className="col-md-3 end-sidebar-lg">
            <div className="sticky">
              {/*Publicidad derecha*/}
              <aside className="widget">
                <div className="textwidget custom-html-widget">
                  <div className="hover-a text-center">
                    {/*title*/}
                    <div className="py-2">
                      {/*<span className="text-mute small">
                        - Segunda Publicidad -
                      </span>*/}
                    </div>
                    {/*content*/}
                    <a href="#" target="_blank" rel="noopener">
                      <img
                        className="img-fluid-fisionova"
                        src={DrenajeLinfatico}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </aside>
          {/* end right column */}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="row">
          {/* start left column */}
          <div className="col-md-6">
            {/* block start */}
            <div className="block-area">
              {/* block title */}
              <div className="block-title-6 d-none d-sm-block">
                <h4 className="h5 border-danger">
                  <span className="bg-danger text-white">Destacadas</span>
                </h4>
              </div>
              {/* block content */}
              <div className="border-bottom-last-0 first-pt-0">
                {/*post start*/}
                <ErrorBoundary fallback={<ErrorMessage />}>
                  <Articles />
                </ErrorBoundary>
              </div>
              {/* end block content */}
            </div>
            {/*end block*/}
          </div>
          {/* end left column */}

          {/* 5 Noticias */}
          <ErrorBoundary fallback={<ErrorMessage />}>
            <SecondaryArticles />
          </ErrorBoundary>

          {/* start right column ANUNCIO */}
          <aside className="col-md-3 end-sidebar-lg">
            <div className="sticky">
              {/*Publicidad derecha*/}
              <aside className="widget">
                <div className="textwidget custom-html-widget">
                  <div className="hover-a text-center">
                    {/*title*/}
                    <div className="py-2">
                      <span className="text-mute small">
                        - Segunda Publicidad -
                      </span>
                    </div>
                    {/*content*/}
                    <a href="#" target="_blank" rel="noopener">
                      <img
                        className="img-fluid-fisionova"
                        src={DrenajeLinfatico}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </aside>
          {/* end right column */}
        </div>
        {/* Final Noticias */}
        {/* Big grid slider 1 */}
        <ErrorBoundary fallback={<ErrorMessage />}>
          <NewsToday newsData={newsData} />
        </ErrorBoundary>
      </div>
    );
  }
};
export default OrderSection;
