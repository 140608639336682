import useFetchData from "../assets/js/services/useFetchData";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { dateFormat } from "../utils";

const LastNews = ({ idPrincipalArticle, asideClass }) => {
  let location = useLocation();
  // console.log(location);
  const { result, error, loading } = useFetchData(
    `noticias?filters[id][$ne]=${idPrincipalArticle}&sort[0]=orden:asc&sort[1]=id:desc&populate=*`
  );
  // console.log(
  //   `noticias?filters[id][$ne]=${idPrincipalArticle}&sort[0]=orden:asc&sort[1]=id:desc&populate=*`
  // );
  // console.log(error);
  // console.log(loading);
  let principalNew = {};
  if (result !== null) {
    principalNew = result[0]?.attributes; // { titular,contenido,... }
  }
  // console.log(principalNew);
  const secondaryNews = result?.slice(1, 4); // [{ titular,contenido,... }, { titular,contenido,... }, { titular,contenido,... }]
  // console.log(secondaryNews);
  const urlNoticias = location.pathname.split("/");
  // console.log(urlNoticias);

  // const urlPost = `https://peppy-kheer-a4fc23.netlify.app/${urlNoticias[1]}/${urlNoticias[2]}/`;
  // console.log(urlPost);
  return (
    <aside className={asideClass}>
      {/*Block title*/}
      <div className="block-title-4">
        <h4 className="h5 title-arrow">
          <span>Ultimas noticias!!</span>
        </h4>
      </div>
      {/*post big start*/}
      <div className="big-post">
        <article className="card card-full hover-a mb-4">
          {/*thumbnail*/}
          <div className="ratio_360-202 image-wrapper">
            <a href={`${principalNew?.slug}`}>
              <img
                className="img-fluid"
                src={principalNew?.imagen?.data?.attributes?.url}
                alt={principalNew?.imagen?.data?.attributes?.alternativeText}
                loading="lazy"
              />
            </a>
          </div>
          <div className="card-body">
            {/*title*/}
            <h2 className="card-title h1-sm h3-md">
              <a
                href={`https://www.datapolis.bo/post/noticias/${principalNew?.slug}`}
              >
                {principalNew?.titular}
              </a>
            </h2>
            {/* author & date */}
            <div className="card-text mb-2 text-muted small">
              {/* <span className="d-none d-sm-inline me-1">
                <a className="fw-bold" href="#">
                  John Doe
                </a>
              </span> */}
              <time dateTime="2019-10-22">
                {dateFormat(principalNew?.createdAt)}
              </time>
            </div>
            {/*description*/}
            <p className="card-text">{principalNew?.pretitular}</p>
          </div>
        </article>
      </div>
      {/*end post big*/}
      {/*post small start*/}
      <div className="small-post">
        {/*post list*/}
        {secondaryNews?.map((article) => (
          <article key={article?.id} className="card card-full hover-a mb-4">
            <div className="row">
              {/*thumbnail*/}
              <div className="col-3 col-md-4 pe-2 pe-md-0">
                <div className="ratio_110-77 image-wrapper">
                  <a
                    href={`https://www.datapolis.bo/post/noticias/${article?.attributes.slug}`}
                  >
                    <img
                      className="img-fluid"
                      src={article?.attributes.imagen.data.attributes.url}
                      alt={article?.attributes.titular}
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              {/* title & date */}
              <div className="col-9 col-md-8">
                <div className="card-body pt-0">
                  <h3 className="card-title h3 h5-sm h6-md">
                    {/* TODO: cambiar la dirección peppy-kheer-a4fc23.netlify.app*/}
                    <a
                      href={`https://www.datapolis.bo/post/noticias/${article?.attributes.slug}`}
                    >
                      {article?.attributes.titular}
                    </a>
                  </h3>
                  <div className="card-text small text-muted">
                    <time dateTime="2019-10-16">
                      {dateFormat(principalNew?.createdAt)}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
        {/*End post list*/}
      </div>
    </aside>
  );
};
LastNews.propTypes = {
  idPrincipalArticle: PropTypes.number,
  asideClass: PropTypes.string,
};
export default LastNews;
