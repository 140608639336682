import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const TermsContactPage = () => {
  return (
    <>
      <Header />
      {/* ========== WRAPPER ========== */}
      <div className="wrapper">
        <main id="content">
          <div className="container">
            <div className="row">
              {/*breadcrumb*/}
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb u-breadcrumb pt-3 px-0 mb-0 bg-transparent small d-none">
                    <li className="breadcrumb-item">
                      <a href="../home/index.html">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active d-none d-md-block"
                      aria-current="page"
                    >
                      Term Of Use
                    </li>
                  </ol>
                </nav>
              </div>
              {/*end breadcrumb*/}

              {/*content*/}
              <div className="col-md-8">
                {/* post title */}
                <div className="post-title">
                  <h1 className="h1 display-4-md display-3-lg mt-2">
                    Term Of Use
                  </h1>
                </div>
                {/* post content */}
                <div className="post-content">
                  <p>
                    Please read these Terms of Service (Terms, Terms of Service)
                    carefully before using the{" "}
                    <span style={{ background: "#FFF2CC" }}>
                      https://www.mywebsite.com
                    </span>{" "}
                    website and the My Mobile App (the Service) operated by{" "}
                    <span style={{ background: "#FFF2CC" }}>My Company</span>.
                  </p>
                  <p>
                    Your access to and use of the Service is conditioned on your
                    acceptance of and compliance with these Terms. These Terms
                    apply to all visitors, users and others who access or use
                    the Service.
                  </p>
                  <p>
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms then
                    you may not access the Service.
                  </p>
                  <h3>Termination</h3>
                  <p>
                    We may terminate or suspend access to our Service
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms.
                  </p>
                  <p>
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability.
                  </p>
                  <h3>Subscriptions</h3>
                  <p>
                    Some parts of the Service are billed on a subscription basis
                    (Subscription(s)). You will be billed in advance on a
                    recurring
                  </p>
                  <p>
                    The Subscriptions section is for SaaS businesses. For the
                    full disclosure section or for a “Purchases” section, create
                    your own Terms of Service.
                  </p>
                  <h3>Content</h3>
                  <p>
                    Our Service allows you to post, link, store, share and
                    otherwise make available certain information, text,
                    graphics, videos, or other material (Content). You are
                    responsible for the
                  </p>
                  <p>
                    The Content section is for businesses that allow users to
                    create, edit, share, make content on their websites or apps.
                    For the full disclosure section, create your own Terms of
                    Service.
                  </p>
                  <h3>Links To Other Web Sites</h3>
                  <p>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by{" "}
                    <span style={{ background: "#FFF2CC" }}>My Company</span>.
                  </p>
                  <p>
                    <span style={{ background: "#FFF2CC" }}>My Company</span>{" "}
                    has no control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third party
                    web sites or services. You further acknowledge and agree
                    that{" "}
                    <span style={{ background: "#FFF2CC" }}>My Company</span>{" "}
                    shall not be responsible or liable, directly or indirectly,
                    for any damage or loss caused or alleged to be caused by or
                    in connection with use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                  </p>
                  <h3>Changes</h3>
                  <p>
                    We reserve the right, at our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    we will try to provide at least 30 days notice prior to any
                    new terms taking effect. What constitutes a material change
                    will be determined at our sole discretion.
                  </p>
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions about these Terms, please contact
                    us.
                  </p>
                  <p>Last updated: Jan 01, 2019</p>
                </div>
              </div>
              {/*end content*/}

              {/* start right column */}
              <aside className="col-md-4">
                {/*sticky*/}
                <div className="sticky">
                  {/*Advertiser*/}
                  <aside className="widget">
                    <div className="hover-a text-center">
                      {/*title*/}
                      <div className="pb-2">
                        <span className="text-mute small">
                          - Advertisement -
                        </span>
                      </div>
                      {/*content*/}
                      <a href="#" target="_blank">
                        <img
                          className="img-fluid bg-light"
                          src="../../assets/img/ads/300-demo.png"
                          alt="ads space"
                        />
                      </a>
                    </div>
                    <div className="gap-15"></div>
                  </aside>
                  {/*end advertiser*/}

                  {/* Social icon */}
                  <aside className="widget">
                    {/*block title*/}
                    <div className="block-title-4">
                      <h4 className="h5 title-arrow">
                        <span>Redes Sociales</span>
                      </h4>
                    </div>
                    {/*end block title*/}
                    {/* social content */}
                    <ul className="list-unstyled social-two">
                      <li className="facebook">
                        <a
                          className="bg-facebook text-white"
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="facebook"
                        >
                          Facebook
                        </a>
                      </li>
                      <li className="twitter">
                        <a
                          className="bg-twitter text-white"
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="twitter"
                        >
                          Twitter
                        </a>
                      </li>
                      <li className="instagram">
                        <a
                          className="bg-instagram text-white"
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="instagram"
                        >
                          Instagram
                        </a>
                      </li>
                      <li className="youtube">
                        <a
                          className="bg-youtube text-white"
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="youtube"
                        >
                          Youtube
                        </a>
                      </li>
                    </ul>
                    {/* end social content */}
                    <div className="gap-1"></div>
                  </aside>
                  {/* End social icon */}

                  {/* latest post */}
                  <aside className="widget">
                    {/*Block title*/}
                    <div className="block-title-4">
                      <h4 className="h5 title-arrow">
                        <span>Ultimas noticias</span>
                      </h4>
                    </div>
                    {/*post big start*/}
                    <div className="big-post">
                      <article className="card card-full hover-a mb-4">
                        {/*thumbnail*/}
                        <div className="ratio_360-202 image-wrapper">
                          <a href="#">
                            <img
                              className="img-fluid lazy"
                              src="../../assets/img/assets/lazy-empty.png"
                              data-src="../../assets/img/360x202/img55.jpg"
                              alt="Image description"
                            />
                          </a>
                        </div>
                        <div className="card-body">
                          {/*title*/}
                          <h2 className="card-title h1-sm h3-md">
                            <a href="#">
                              MotoGP: Which team needs what in 2019?
                            </a>
                          </h2>
                          {/* author & date */}
                          <div className="card-text mb-2 text-muted small">
                            <span className="d-none d-sm-inline me-1">
                              <a className="fw-bold" href="#">
                                John Doe
                              </a>
                            </span>
                            <time dateTime="2019-10-22">Oct 22, 2019</time>
                          </div>
                          {/*description*/}
                          <p className="card-text">
                            This is a wider card with supporting text below as a
                            natural lead-in to additional content. This content
                            is a little bit longer.
                          </p>
                        </div>
                      </article>
                    </div>
                    {/*end post big*/}
                    {/*post small start*/}
                    <div className="small-post">
                      {/*post list*/}
                      <article className="card card-full hover-a mb-4">
                        <div className="row">
                          {/*thumbnail*/}
                          <div className="col-3 col-md-4 pe-2 pe-md-0">
                            <div className="ratio_110-77 image-wrapper">
                              <a href="#">
                                <img
                                  className="img-fluid lazy"
                                  src="../../assets/img/assets/lazy-empty.png"
                                  data-src="../../assets/img/110x77/img5.jpg"
                                  alt="Image description"
                                />
                              </a>
                            </div>
                          </div>
                          {/* title & date */}
                          <div className="col-9 col-md-8">
                            <div className="card-body pt-0">
                              <h3 className="card-title h6 h5-sm h6-md">
                                <a href="#">
                                  Take the kids to Windermere Jetty, Lake
                                  District
                                </a>
                              </h3>
                              <div className="card-text small text-muted">
                                <time dateTime="2019-10-16">Oct 16, 2019</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                      {/*post list*/}
                      <article className="card card-full hover-a mb-4">
                        <div className="row">
                          {/*thumbnail*/}
                          <div className="col-3 col-md-4 pe-2 pe-md-0">
                            <div className="ratio_110-77 image-wrapper">
                              <a href="#">
                                <img
                                  className="img-fluid lazy"
                                  src="../../assets/img/assets/lazy-empty.png"
                                  data-src="../../assets/img/110x77/img6.jpg"
                                  alt="Image description"
                                />
                              </a>
                            </div>
                          </div>
                          {/* title & date */}
                          <div className="col-9 col-md-8">
                            <div className="card-body pt-0">
                              <h3 className="h6 h4-sm h6-md">
                                <a href="#">
                                  Wave reviews: Under Norways new underwater
                                  restaurant
                                </a>
                              </h3>
                              <div className="small text-muted">
                                <time dateTime="2019-10-15">Oct 15, 2019</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                      {/*post list*/}
                      <article className="card card-full hover-a mb-4">
                        <div className="row">
                          {/*thumbnail*/}
                          <div className="col-3 col-md-4 pe-2 pe-md-0">
                            <div className="ratio_110-77 image-wrapper">
                              <a href="#">
                                <img
                                  className="img-fluid lazy"
                                  src="../../assets/img/assets/lazy-empty.png"
                                  data-src="../../assets/img/110x77/img7.jpg"
                                  alt="Image description"
                                />
                              </a>
                            </div>
                          </div>
                          {/* title & date */}
                          <div className="col-9 col-md-8">
                            <div className="card-body pt-0">
                              <h3 className="h6 h4-sm h6-md">
                                <a href="#">
                                  This U.S. Airline Has More Legroom Than Any
                                  Other
                                </a>
                              </h3>
                              <div className="small text-muted">
                                <time dateTime="2019-10-14">Oct 14, 2019</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                      {/*End post list*/}
                    </div>
                    {/*end post small*/}
                    <div className="gap-0"></div>
                  </aside>
                  {/* end latest post */}
                </div>
                {/*End sticky*/}
              </aside>
              {/* end right column */}
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};
export default TermsContactPage;
