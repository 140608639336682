import { AiOutlineClose } from "react-icons/ai";

const ErrorMessage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
        width: "40%",
      }}
      className="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <p>
        <strong>Lo sentimos</strong>, hubo un error al mostrar la información
      </p>
      <button
        type="button"
        data-bs-dismiss="alert"
        aria-label="Close"
        style={{ borderRadius: "10px" }}
      >
        {/* <span >×</span> */}
        <AiOutlineClose aria-hidden="true" />
      </button>
    </div>
  );
};
export default ErrorMessage;
