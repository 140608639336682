import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import RelatedPostsCarousel from "../components/RelatedPostsCarousel";
import useFetchData from "../assets/js/services/useFetchData";
import { useLocation, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { changeStringTagsToArray, dateFormat } from "../utils";
import SocialComponent from "../components/SocialComponent";
import MetaTags from "../components/MetaTags";
import PostTags from "../components/PostTags";
import LastNews from "../components/LastNews";
import { ErrorBoundary } from "react-error-boundary";
import BannerDerecha from "../../src/assets/img/assets/banner-derecha.gif";
import VideosPost from "../components/VideosPost";
import AuthorDetails from "../components/AuthorDetails";

const PostPage = () => {
  let location = useLocation();
  const { type, slug } = useParams();
  let url = "";
  if (type === "newsToday") {
    url = `noticia-hoys?filters[slug][$eq]=${slug}&populate=*`;
  } else if (type === "bigdataempresas") {
    url = `bigdataempresas?filters[slug][$eq]=${slug}&populate=*`;
  } else {
    url = `noticias?filters[slug][$eq]=${slug}&populate=*`;
  }
  // console.log(url);
  const { result, error, loading } = useFetchData(url);
  // console.log(error);
  // console.log(result);
  // console.log(loading);
  let dataNew = {};
  // console.log(result);
  if (result !== null) {
    dataNew = result[0]?.attributes;
  }
  const markup =
    type == "newsToday"
      ? { __html: dataNew?.audioEnlace }
      : { __html: dataNew?.enlaceAudio };
  const imageUrl =
    type == "newsToday"
      ? dataNew?.imagenes?.data[0].attributes.url
      : dataNew?.imagen?.data.attributes.url;
  // const urlPost = `https://peppy-kheer-a4fc23.netlify.app${location.pathname}`;
  const urlPost = `https://www.datapolis.bo/${location.pathname}`;
  const tags = changeStringTagsToArray(dataNew?.etiquetas);
  // console.log(dataNew);
  return (
    <>
      <MetaTags dataNew={dataNew} imageUrl={imageUrl} urlPost={urlPost} />
      <Header />
      {/* ========== WRAPPER ========== */}
      <div className="wrapper">
        {/*Content start*/}
        <main id="content">
          <div className="container">
            <div className="row">
              {/*breadcrumb*/}
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb u-breadcrumb pt-3 px-0 mb-0 bg-transparent small d-none">
                    <li className="breadcrumb-item">
                      <a href="../home/index.html">Principal</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="../category/category.html">Noticias</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="../category/category2.html">Destacada</a>
                    </li>
                    <li
                      className="breadcrumb-item active d-none d-md-block"
                      aria-current="page"
                    >
                      Rubiales cambia de posición: «¡No voy a dimitir!»
                    </li>
                  </ol>
                </nav>
              </div>
              {/*end breadcrumb*/}

              {/*content*/}
              <div className="col-md-8">
                <div className="post-title mb-4">
                  {/* title */}
                  <h1 className="entry-title display-4 display-2-lg mt-2">
                    {dataNew?.titular}
                  </h1>
                  {/* atribute */}
                  <div className="post-atribute mb-3">
                    <div className="my-3 text-muted small">
                      {/*author*/}
                      <span className="d-none d-sm-inline">
                        by{" "}
                        <a className="fw-bold" href="../category/author.html">
                          Datapolis
                        </a>
                      </span>
                      {/*date*/}
                      <time
                        className="ms-0 ms-sm-2 ms-md-3"
                        dateTime="2023-08-25"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          className="bi bi-calendar-check me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                        </svg>{" "}
                        {dataNew?.createdAt && dateFormat(dataNew?.createdAt)}
                      </time>
                    </div>
                    <SocialComponent urlPost={urlPost} />
                  </div>
                </div>

                {/* post content */}
                <div className="post-content">
                  {/* imagen central */}
                  <figure>
                    <img
                      className="image-full-container"
                      src={imageUrl}
                      alt="Image description"
                    />
                  </figure>
                  {/* Cuerpo de la noticia */}
                  {/* Audio */}
                  {dataNew?.audioEnlace !== null && (
                    <div dangerouslySetInnerHTML={markup}></div>
                  )}
                  <span className="dropcaps dropcaps-one">
                    {dataNew?.contenido?.charAt(0)}
                  </span>
                  <ReactMarkdown className="img-post-page">
                    {dataNew?.contenido?.substring(
                      1,
                      dataNew?.contenido.length
                    )}
                  </ReactMarkdown>
                </div>

                {/* footer post */}
                <PostTags tags={tags} />
                <hr />
                {/* author */}
                {type !== "newsToday" ? (
                  <>
                    <AuthorDetails author={dataNew?.autor} />
                    <hr />
                  </>
                ) : (
                  ""
                )}

                {/*Related Articles*/}
                {type !== "newsToday" ? (
                  <div className="related-post mb-4">
                    <div className="block-title-6">
                      <h4 className="h5 border-primary border-2">
                        <span className="bg-primary text-white">
                          Artículos relacionados
                        </span>
                      </h4>
                    </div>
                    <div className="row col-12">
                      <RelatedPostsCarousel
                        idPrincipalNews={result !== null ? result[0]?.id : 1}
                        idCategory={dataNew?.categoria?.data?.id}
                        type={type}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* end content */}

              {/* start right column */}
              <aside className="col-md-4">
                {/*sticky*/}
                <div className="sticky">
                  {/* latest post */}
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <LastNews
                      idPrincipalArticle={result !== null ? result[0]?.id : 1}
                      asideClass={"widget"}
                    />
                  </ErrorBoundary>
                  {/* end latest post */}
                  <div className="gap-0"></div>
                  {/*VideosPost*/}
                  {/* Primer video */}
                  <aside className="widget">
                    <div className="block-title-6">
                      <h4 className="h5 border-primary">
                        <span className="bg-primary text-white">
                          Videogalería
                        </span>
                      </h4>
                    </div>
                    <VideosPost />
                  </aside>

                  {/*Publicidad derecha*/}
                  <aside className="widget">
                    <div className="textwidget custom-html-widget col-12 col-lg-12">
                      <div className="hover-a text-center">
                        {/*title*/}
                        <div className="py-1">
                          <span className="text-mute small">
                            - Tercera Publicidad -
                          </span>
                        </div>
                        {/*content*/}
                        <a href="#" target="_blank" rel="noopener">
                          <img
                            width="60%"
                            className="img-fluid bg-light"
                            src={BannerDerecha}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </aside>
                </div>
                {/*End sticky*/}
              </aside>
              {/* end right column */}
            </div>
          </div>
        </main>
        {/*End Content*/}
      </div>
      <Footer />
    </>
  );
};
export default PostPage;
