import useFetchData from "../assets/js/services/useFetchData";

const VideosPost = () => {
  const { error, result, loading } = useFetchData(
    `video-galerias?sort[0]=orden:asc&sort[1]=id:desc&populate=*`
  );
  const firstThreeVideos = result?.slice(0, 3);
  // console.log(firstThreeVideos);
  // console.log(error);
  // console.log(loading);
  return (
    <>
      {firstThreeVideos?.map((video) => (
        <article key={video.id} className="col-12 col-lg-12 link">
          <div className="card card-full hover-a mb-3">
            {/*thumbnail, category & icon*/}
            <div className="overflow zoom">
              <a href={video?.attributes?.url} target="_blank" rel="noreferrer">
                <img
                  className="img-fluid entered loaded"
                  src={video?.attributes?.imagen?.data?.attributes?.url}
                  alt={video?.attributes?.titulo}
                  data-ll-status="loaded"
                  loading="lazy"
                />
                <div className="post-type-icon">
                  <span className="fa-stack-sea text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-play-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path>
                    </svg>
                  </span>
                </div>
              </a>
            </div>
            {/* title, date & comments */}
            <div className="card-body">
              <h4 className="card-title h6 text-center">
                <a
                  href={video?.attributes?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {video?.attributes?.titulo}
                </a>
              </h4>
            </div>
          </div>
        </article>
      ))}
    </>
  );
};
export default VideosPost;
