import { useState } from "react";
import PropTypes from "prop-types";
import ScrollTrigger from "react-scroll-trigger";
import { useTypingEffect } from "../hooks/typing-effect";

const DontForget = ({ frase = "Hoy no olvides" }) => {
  const typingFrase = useTypingEffect(frase, 50);
  const [dontForgetOn, setDontForgetOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setDontForgetOn(true)}
      onExit={() => setDontForgetOn(false)}
    >
      <article className="col-12 mt-3">
        <div className="bg-themes p-3 p-lg-3 rounded border-bottom shadow-lrb-lg">
          {/*post title*/}
          <h4 className="h4 h1-md display-4-lg text-center fw-bold">
            No olvides
          </h4>
          <div className="text-muted mb-2">
            {/*No olvides*/}
            <p>{dontForgetOn && typingFrase}</p>
          </div>
        </div>
      </article>
    </ScrollTrigger>
  );
};
DontForget.propTypes = {
  frase: PropTypes.string,
};
export default DontForget;
