import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterMain = ({ count, descCount }) => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <article className="col-12 level">
        <div className="bg-themes p-lg-3 shadow-lrb-lg rounded border-bottom">
          {/*post title*/}
          <div className="display-4-lg text-center fw-bold text-bg-primary rounded border-bottom">
            <p className="text-white mb-0">La cifra del día</p>
            <p className="display-3 text-white fw-bold">
              {counterOn && (
                <CountUp start={0} end={count} duration={2} decimal="," />
              )}
            </p>
          </div>
          <div>
            <p className="text-primary mb-1 mt-1">{descCount}</p>
          </div>
        </div>
      </article>
    </ScrollTrigger>
  );
};
export default CounterMain;
