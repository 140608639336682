import { Link } from "react-router-dom";
import useFetchData from "../assets/js/services/useFetchData";
import { categoriesColors, dateFormat, uniqueOrdenTipoValues } from "../utils";

const BigDataEnterprises = () => {
  const { result, error, loading } = useFetchData(
    "bigdataempresas?sort[0]=orden:asc&sort[1]=id:desc&populate=*"
  );
  // Filtrar por tipo empresa
  const uniqueArticlesEnterprise = uniqueOrdenTipoValues(
    result,
    "orden",
    "empresa"
  );
  // Filtrar por tipo bigdata
  const uniqueArticlesBigData = uniqueOrdenTipoValues(
    result,
    "orden",
    "bigdata"
  );
  const enterpiseArticles = uniqueArticlesEnterprise?.slice(0, 2);
  // console.log(enterpiseArticles);
  const bigdataArticles = uniqueArticlesBigData?.slice(0, 2);

  return (
    <>
      {/* 4 Noticias para empresas y bigdata */}
      <aside className="col-md-4 d-sm-block">
        {/* sidebar sticky start */}
        <div>
          {/*widget start*/}
          <aside className="widget">
            {/* block title */}
            <div className="block-title-6">
              <h4 className="h6 border-danger">
                <span className="bg-danger text-white">
                  Empresas - Emprendimientos
                </span>
              </h4>
            </div>
            {/* block content */}
            <article className="card card-full hover py-1">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  {/*post list*/}
                  {enterpiseArticles?.map((article) => (
                    <div className="small-post" key={crypto.randomUUID()}>
                      <article className="card-full hover-a mb-4">
                        <div className="row">
                          {/*thumbnail*/}
                          <div className="col-3 col-md-4 pe-2 pe-md-0">
                            <div className="ratio_110-77 image-wrapper">
                              <a href="#">
                                <img
                                  className="img-fluid"
                                  src={
                                    article?.attributes.imagen.data.attributes
                                      .url
                                  }
                                  alt="Image description"
                                />
                              </a>
                              {/* category destacada */}
                              <div className="position-absolute px-1 pb-05 pt-0 b-0 w-100 bg-shadow">
                                <a
                                  className={`p-1 badge ${categoriesColors(
                                    article?.attributes.categoria.data
                                      .attributes.descripcion
                                  )} text-white`}
                                  href="#"
                                >
                                  {
                                    article?.attributes.categoria.data
                                      .attributes.descripcion
                                  }
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* title & date */}
                          <div className="col-9 col-md-8">
                            <div className="card-body pt-0">
                              <h3
                                className="card-title h3 h5-sm h6-md"
                                style={{ marginBottom: 0 }}
                              >
                                <Link
                                  to={`/post/bigdataempresas/${article?.attributes.slug}`}
                                >
                                  {article?.attributes.titular}
                                </Link>
                              </h3>
                              <div className="py-1 card-text small text-muted">
                                <time dateTime="2023-10-21">
                                  {dateFormat(article?.attributes.createdAt)}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}

                  {/*End post list*/}
                </div>
              </div>
            </article>

            {/*end block content*/}
            <div className="gap-0"></div>
          </aside>
          {/*End widget*/}
        </div>
        {/*end Sidebar sticky*/}
      </aside>
      <aside className="col-md-4 d-sm-block">
        {/* sidebar sticky start */}
        <div>
          {/*widget start*/}
          <aside className="widget">
            {/* block title */}
            <div className="block-title-6">
              <h4 className="h6 border-primary">
                <span className="bg-primary text-white">BigData</span>
              </h4>
            </div>
            {/* block content */}
            <article className="card card-full hover-a py-1">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  {/*post list*/}
                  {bigdataArticles?.map((article) => (
                    <div className="small-post" key={crypto.randomUUID()}>
                      <article className="card-full hover-a mb-4">
                        <div className="row">
                          {/*thumbnail*/}
                          <div className="col-3 col-md-4 pe-2 pe-md-0">
                            <div className="ratio_110-77 image-wrapper">
                              <a href="#">
                                <img
                                  className="img-fluid"
                                  src={
                                    article?.attributes.imagen.data.attributes
                                      .url
                                  }
                                  alt="Image description"
                                />
                              </a>
                              {/* category destacada */}
                              <div className="position-absolute px-1 pb-05 pt-0 b-0 w-100 bg-shadow">
                                <a
                                  className={`p-1 badge ${categoriesColors(
                                    article?.attributes.categoria.data
                                      .attributes.descripcion
                                  )} text-white`}
                                  href="#"
                                >
                                  {
                                    article?.attributes.categoria.data
                                      .attributes.descripcion
                                  }
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* title & date */}
                          <div className="col-9 col-md-8">
                            <div className="card-body pt-0">
                              <h3
                                className="card-title h3 h5-sm h6-md"
                                style={{ marginBottom: 0 }}
                              >
                                <Link
                                  to={`/post/bigdataempresas/${article?.attributes.slug}`}
                                >
                                  {article?.attributes.titular}
                                </Link>
                              </h3>
                              <div className="py-1 card-text small text-muted">
                                <time dateTime="2023-10-21">
                                  {dateFormat(article?.attributes.createdAt)}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}

                  {/*End post list*/}
                </div>
              </div>
            </article>

            {/*end block content*/}
            <div className="gap-0"></div>
          </aside>
          {/*End widget*/}
        </div>
        {/*end Sidebar sticky*/}
      </aside>
    </>
  );
};
export default BigDataEnterprises;
