// URL API
export const API_URL = "https://strapi-production-eed3.up.railway.app/api/";

export function dateFormat(date) {
  const dateTime = new Date(date);
  const dayDigits = dateTime.toLocaleString("default", { day: "2-digit" });
  const fullYear = dateTime.getFullYear();
  let monthDesc = dateTime.toLocaleString("default", { month: "short" });
  monthDesc = monthDesc.charAt(0).toUpperCase() + monthDesc.slice(1);

  // Obtener la hora en formato HH:MM:SS
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");

  return `${monthDesc} ${dayDigits}, ${fullYear} ${hours}:${minutes}`;
}
// Filtra los valores unicos mediante los parametros array de objetos y el valor del filtrado
export const uniqueOrdenValues = (result, unique) => {
  return result?.filter(
    (a, i) =>
      result.findIndex((s) => a.attributes[unique] === s.attributes[unique]) ===
      i
  );
};

export const uniqueOrdenTipoValues = (result, unique, type) => {
  const sameType = result?.filter((a) => a.attributes["tipo"] === type);
  return uniqueOrdenValues(sameType, unique);
};

export function categoriesColors(category) {
  const categoriesColors = {
    Empresa: "bg-purple",
    Economía: "bg-yellow",
    Deportes: "bg-blue",
    Internacional: "bg-danger",
    Política: "bg-success",
    Reportaje: "bg-reportaje",
  };
  return categoriesColors[category] || "bg-blue";
}
export function actualDate() {
  // Obtener la fecha actual
  const actualDate = new Date();

  // Opciones para formatear la fecha
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Formatear la fecha usando toLocaleString
  const dateParts = actualDate
    .toLocaleString("default", dateOptions)
    .split(", ");
  dateParts[0] = dateParts[0][0].toUpperCase() + dateParts[0].slice(1);
  // Dividir la fecha en sus partes
  const completeDate = dateParts[1].split(" ");
  // Convertir la primera letra del mes en mayúscula
  completeDate[2] =
    completeDate[2].charAt(0).toUpperCase() + completeDate[2].slice(1);
  // Unir las partes de nuevo
  dateParts[1] = completeDate.join(" ");
  return dateParts.join(", ");
}
export function changeStringTagsToArray(tags) {
  // "etiquetas": "#CNI, #EMPODERAMIENTO, #DID",
  return tags?.split(", ");
}
