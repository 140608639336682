import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const AboutPage = () => {
  return (
    <>
      <Header />
      {/* ========== WRAPPER ========== */}

      <div className="wrapper">
        {/*Content start*/}
        <main id="content">
          <div className="container">
            <div className="row">
              {/*breadcrumb*/}
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb u-breadcrumb pt-3 px-0 mb-0 bg-transparent small d-none">
                    <li className="breadcrumb-item">
                      <Link to={"/"} className="nav-link active">
                        Principal
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active d-none d-md-block"
                      aria-current="page"
                    >
                      Acerca de Datápolis
                    </li>
                  </ol>
                </nav>
              </div>
              {/*end breadcrumb*/}
              {/*content*/}
              <div className="col-12">
                <div className="post-title text-center">
                  <h1 className="h1 display-4-md display-3-lg mt-2">
                    Datápolis
                  </h1>
                  <hr className="hr-after mx-auto" />
                </div>
                <div className="entry-content post-content post-page">
                  <p>
                    We are a solid company and always prioritize consumer
                    convenience. We have a great mission to continue to innovate
                    and develop in everything. If you are looking for a solid
                    company, we are the answer you are looking for. We were
                    established since 2019 and have served many domestic and
                    foreign consumers.{" "}
                  </p>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using Content here, content here, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for orem ipsum will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <hr className="my-4" />
                  <h3 className="text-center">Nuestro servicio</h3>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don not
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there is not
                    anything embarrassing hidden in the middle of text. All the
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined chunks as necessary, making this the first true
                    generator on the Internet.
                  </p>
                  <hr className="my-4" />
                  <h3 className="text-center">Nuestro equipo</h3>
                  <ul className="row list-unstyled">
                    <li className="col-12 col-md-6 col-lg-3 text-center">
                      <div className="team-profile">
                        <figure>
                          <img
                            src="./src/assets/img/avatar/team1.png"
                            className="img-fluid"
                            alt="John Doe"
                          />
                        </figure>
                        <h3>
                          <a href="#">John Doe</a>
                        </h3>
                        <p>Front Designer</p>
                        <ul className="follow-us list-unstyled clearfix">
                          <li>
                            <a className="text-facebook" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-facebook me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-twitter" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-twitter me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-instagram" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                viewBox="0 0 512 512"
                              >
                                <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                                <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                                <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="col-12 col-md-6 col-lg-3 text-center">
                      <div className="team-profile">
                        <figure>
                          <img
                            src="./src/assets/img/avatar/team2.png"
                            className="img-fluid"
                            alt="Arjun"
                          />
                        </figure>
                        <h3>
                          <a href="#">Arjun</a>
                        </h3>
                        <p>Programmer</p>
                        <ul className="follow-us list-unstyled clearfix">
                          <li>
                            <a className="text-facebook" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-facebook me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-twitter" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-twitter me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-instagram" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                viewBox="0 0 512 512"
                              >
                                <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                                <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                                <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="col-12 col-md-6 col-lg-3 text-center">
                      <div className="team-profile">
                        <figure>
                          <img
                            src="./src/assets/img/avatar/team3.png"
                            className="img-fluid"
                            alt="Monela"
                          />
                        </figure>
                        <h3>
                          <a href="#">Monela</a>
                        </h3>
                        <p>Marketing</p>
                        <ul className="follow-us list-unstyled clearfix">
                          <li>
                            <a className="text-facebook" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-facebook me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-twitter" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-twitter me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-instagram" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                viewBox="0 0 512 512"
                              >
                                <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                                <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                                <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="col-12 col-md-6 col-lg-3 text-center">
                      <div className="team-profile">
                        <figure>
                          <img
                            src="./src/assets/img/avatar/team4.png"
                            className="img-fluid"
                            alt="Lee Jun Ho"
                          />
                        </figure>
                        <h3>
                          <a href="#">Lee Jun Ho</a>
                        </h3>
                        <p>Editor</p>
                        <ul className="follow-us list-unstyled clearfix">
                          <li>
                            <a className="text-facebook" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-facebook me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-twitter" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                className="bi bi-twitter me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a className="text-instagram" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.5rem"
                                height="1.5rem"
                                fill="currentColor"
                                viewBox="0 0 512 512"
                              >
                                <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                                <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                                <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <div className="gap-3"></div>
                </div>
              </div>
              {/*end content*/}
            </div>
          </div>
        </main>
        {/*End Content*/}
      </div>
      {/* ========== END WRAPPER ========== */}
      <Footer />
      {/*Back to top*/}
    </>
  );
};
export default AboutPage;
