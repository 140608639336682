import useFetchData from "../assets/js/services/useFetchData";
// import { uniqueOrdenValues } from "../utils";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination } from "swiper/modules";

const LastMomentGallery = () => {
  const { error, result, loading } = useFetchData(
    `ultimomomentos?sort[0]=orden:asc&sort[1]=id:desc&populate=*`
  );
  // console.log(result);
  // console.log(error);
  // console.log(loading);
  // const uniqueVideos = uniqueOrdenValues(result, "orden");
  return (
    <>
      <div className="col-12 pt-2 pb-3 mb-2 bg-last-moment rounded-3">
        <div className="block-title-6">
          <h4 className="h5 border-danger">
            <span className="bg-danger text-white">Ultimo momento</span>
          </h4>
        </div>

        <Swiper
          slidesPerView={2}
          spaceBetween={30}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
        >
          {result?.map((item, index) => {
            // const color = index % 2 === 0 ? "text-black" : "text-primary";
            const color = "text-black";
            const icon = index % 2 === 0 ? "fa-stack-sea--red" : "fa-stack-sea";
            return (
              <SwiperSlide
                key={item?.id}
                className={`swiper-slide--video-gallery link ${color}`}
              >
                <div className="col-md-12 pt-05 pb-05 pe-md-05">
                  <div className="col-md-12 pt-05 pb-05 pe-md-05">
                    {/*title Video*/}
                    <a
                      rel="noopener noreferrer"
                      href={item?.attributes?.url}
                      target="_blank"
                    >
                      <h6
                        className={`display-5-lg heading-letter-spacing ${color}`}
                      >
                        {item?.attributes?.titulo}
                      </h6>
                    </a>
                  </div>
                  <div className="card-full text-white overflow zoom rounded-3">
                    <div>
                      {/*thumbnail*/}
                      <a
                        rel="noopener noreferrer"
                        href={item?.attributes?.url}
                        target="_blank"
                      >
                        <img
                          height="340vh"
                          width="250vw"
                          // className="image-full-container"
                          src={item?.attributes?.imagen.data.attributes.url}
                          alt="Image description"
                          loading="lazy"
                        />
                        <div className="post-type-icon">
                          <span className={`${icon} ${color}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-play-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default LastMomentGallery;
