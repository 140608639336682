import { Link } from "react-router-dom";
import useFetchData from "../assets/js/services/useFetchData";
import { categoriesColors, dateFormat, uniqueOrdenValues } from "../utils";

const SecondaryArticles = () => {
  const { result, error, loading } = useFetchData(
    "noticias?sort[0]=orden:asc&sort[1]=id:desc&populate=*"
  );
  // console.log(error);
  // console.log(loading);
  // console.log(result);
  const uniqueArticles = uniqueOrdenValues(result, "orden");
  const secondaryArticles = uniqueArticles?.slice(2, 7);
  // console.log(secondaryArticles);
  return (
    <>
      {/* 5 Noticias dispositivo mediano a grande*/}
      <aside className="col-md-3 d-sm-block">
        {/* sidebar sticky start */}
        <div className="sticky">
          {/*widget start*/}
          <aside className="widget">
            {/* block title */}
            <div className="block-title-4">
              <h4 className="h5 title-arrow">
                <span>+ Info</span>
              </h4>
            </div>
            {/* block content */}
            <div className="small-post">
              {/*post list*/}
              {secondaryArticles?.map((article) => (
                <article
                  key={crypto.randomUUID()}
                  className="card-full hover-a mb-4"
                >
                  <div className="row">
                    {/*thumbnail*/}
                    <div className="col-3 col-md-4 pe-2 pe-md-0">
                      <div className="ratio_110-77 image-wrapper">
                        <a href="#">
                          <img
                            className="img-fluid"
                            src={article?.attributes.imagen.data.attributes.url}
                            alt="Image description"
                            loading="lazy"
                          />
                        </a>
                        {/* category destacada */}
                        <div className="position-absolute px-1 pb-05 pt-0 b-0 w-100 bg-shadow">
                          <a
                            className={`p-1 badge ${categoriesColors(
                              article?.attributes.categoria.data.attributes
                                .descripcion
                            )} text-white`}
                            href="#"
                          >
                            {
                              article?.attributes.categoria.data.attributes
                                .descripcion
                            }
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* title & date */}
                    <div className="col-9 col-md-8">
                      <div className="card-body pt-0">
                        <h3
                          className="card-title h3 h5-sm h6-md"
                          style={{ marginBottom: 0 }}
                        >
                          <Link
                            to={`/post/noticia/${article?.attributes.slug}`}
                          >
                            {article?.attributes.titular}
                          </Link>
                        </h3>
                        <div className="py-1 card-text small text-muted">
                          <time dateTime="2023-10-21">
                            {dateFormat(article?.attributes.createdAt)}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              ))}

              {/*End post list*/}
            </div>
            {/*end block content*/}
            <div className="gap-0"></div>
          </aside>
          {/*End widget*/}
        </div>
        {/*end Sidebar sticky*/}
      </aside>
      {/* 5 Noticias dispositivo solo mobile*/}
    </>
  );
};
export default SecondaryArticles;
