import { Helmet } from "react-helmet";

const MetaTags = ({ imageUrl, dataNew, urlPost }) => {
  return (
    <Helmet>
      <meta name="image" property="og:image" content={imageUrl} />
      <meta name="image" property="og:image:url" content={imageUrl} />
      <meta name="image" property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta
        name="description"
        property="og:description"
        content={dataNew?.titular}
      />
      <meta property="og:url" content={urlPost} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={dataNew?.titular} />
      <meta property="og:site_name" content="Datapolis" />
      <meta property="og:locale" content="es_ES" />
      <meta name="twitter:title" content={dataNew?.titular} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={dataNew?.titular} />
      <meta name="twitter:site" content={urlPost} />
      <title>{`Datápolis ${dataNew?.slug}`}</title>
    </Helmet>
  );
};
export default MetaTags;
