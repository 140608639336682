import { Carousel } from "react-responsive-carousel";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import useFetchData from "../assets/js/services/useFetchData";
import { dateFormat } from "./../utils";

const RelatedPostsCarousel = ({ idPrincipalNews, idCategory, type }) => {
  let url = "";
  if (type === "bigdataempresas") {
    url = `bigdataempresas?filters[categoria][id][$eq]=${idCategory}&sort[0]=orden:asc&populate=*`;
  } else {
    url = `noticias?filters[categoria][id][$eq]=${idCategory}&sort[0]=orden:asc&populate=*`;
  }
  const { result, error, loading } = useFetchData(url);
  console.log(url);
  console.log(result);
  return (
    <>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="col-12 row"
      >
        {result?.map((item, index) => (
          <>
            <SwiperSlide
              key={item?.id}
              className={`link col-3 col-sm-3 col-md-3 px-1 swiper-slide--related-post`}
            >
              <article>
                {/*post start*/}
                <div className="col-12 mb-3 mb-lg-4">
                  <div className="card-full text-white overflow zoom rounded-3">
                    {/*thumbnail*/}
                    <div>
                      <a href={item?.attributes?.slug}>
                        <img
                          height="150vh"
                          width="250vw"
                          src={item?.attributes?.imagen.data.attributes.url}
                          alt="Image description"
                          loading="lazy"
                        />
                      </a>
                    </div>
                    {/* title & date */}
                    <div className="position-absolute p-3 b-0 w-100 bg-shadow">
                      <a href={item?.attributes?.slug}>
                        <h5 className="card-title h4 h4-sm h5-md text-light my-1 link">
                          {item?.attributes?.titular}
                        </h5>
                      </a>
                      <div className="card-text my-2 dark small text-white">
                        <time dateTime="2019-10-18">
                          {dateFormat(item?.attributes?.createdAt)}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end post */}
              </article>
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </>
  );
};
export default RelatedPostsCarousel;
